import React from 'react';
//Page Components
import Education from '../components/education.component';


const AboutMePage = () => {
    return(
    // <motion.div exit="exit" variants={ pageAnimation } initial="hidden" animate="show">
    <div>
        
        <Education />
    </div>
    // </motion.div>
    )
}

export default AboutMePage;