import React from "react";
//Animations

import ContactForm from '../components/contact.component';

const Contact = () => {
    return(
        <div>
            <ContactForm />  
        </div>
    );
};

export default Contact;
